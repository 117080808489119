import { useEffect, useState } from 'react';
import { Grid } from '../../components/Grid';

import type { GenericHtml as TGenericHtml } from 'server/repository/hygraph/generated/graphqlTypes';
import type { ISharedTemplateProps } from 'templates/TemplateMapper/types';

interface IGenericHTML extends ISharedTemplateProps {
  templateProps: TGenericHtml;
}

export const GenericHTML = ({ templateProps }: IGenericHTML) => {
  const [innerHtml, setInnerHtml] = useState<string>('');

  useEffect(() => {
    const parts = templateProps.html.split(`<script type='text/javascript'>`);
    const scripts = parts
      .filter((part) => part.substring(part.length - 9) === '</script>')
      .map((part) => part.replace('</script>', ''));
    const noScript = parts.filter((part) => part.substring(part.length - 9) !== '</script>');
    if (scripts.length > 0) {
      setInnerHtml(noScript.join(''));
      setTimeout(() => {
        scripts.forEach((script) => {
          window.eval(script);
        });
      }, 100);
    } else {
      setInnerHtml(templateProps.html);
    }
  }, []);

  return (
    <>
      {innerHtml !== '' && (
        <Grid>
          <div dangerouslySetInnerHTML={{ __html: innerHtml }} />
        </Grid>
      )}
    </>
  );
};
